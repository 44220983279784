import React from "react"
import ImageFromStore from "../ImageFromStore/ImageFromStore"
import * as Sentry from "@sentry/react"
import {
	DisplayWidgetType, EntryMockupColorsResponse,
	EntryMockupFontsResponse, ImageResponse, SearchResponse
} from "deblank-api-types"

type Props = {
	widgetType: DisplayWidgetType,
	widgetDetails: ImageResponse | SearchResponse | EntryMockupColorsResponse | EntryMockupFontsResponse,
}

const ImageByWidgetType = (props: Props) => {

	const type = props.widgetType

	switch (type) {
		case DisplayWidgetType.Images: {
			const widgetDetails = props.widgetDetails as ImageResponse
			const altText = widgetDetails.imageAnalysis
				? widgetDetails.imageAnalysis.description
				: widgetDetails.promptRefinedByAgent
			return (
				<ImageFromStore
					imageFileName={widgetDetails.imageFileName}
					altText={altText || ""}
				/>
			)
		}
		case DisplayWidgetType.MockupColors: {
			const widgetDetails = props.widgetDetails as EntryMockupColorsResponse
			const altText = widgetDetails.description
			return (
				<ImageFromStore
					imageFileName={widgetDetails.imageFileName}
					altText={altText}
				/>
			)
		}
		case DisplayWidgetType.MockupFonts: {
			const widgetDetails = props.widgetDetails as EntryMockupFontsResponse
			const altText = widgetDetails.description
			return (
				<ImageFromStore
					imageFileName={widgetDetails.imageFileName}
					altText={altText}
				/>
			)
		}
		case DisplayWidgetType.Searches: {
			const widgetDetails = props.widgetDetails as SearchResponse
			const altText = widgetDetails.imageAnalysis
				? widgetDetails.imageAnalysis.description
				: widgetDetails.promptRefinedByAgent

			return (
				<img key={widgetDetails.id}
					src={widgetDetails.imageUrl}
					alt={altText || ""}
				/>
			)
		}
		case DisplayWidgetType.Text:
		case DisplayWidgetType.Colors:
		case DisplayWidgetType.Fonts:
		case DisplayWidgetType.FontsExplanations:
		case DisplayWidgetType.ColorsExplanations:
		case DisplayWidgetType.AccessibilityColors: {
			return (
				null
			)
		}
		default: {
			const _exhaustiveCheck: never = type
			const errorMessage = `Unknown widget type in for image component: ${_exhaustiveCheck}`
			Sentry.captureMessage(errorMessage)
			throw new Error(errorMessage)
		}
	}
}

export default ImageByWidgetType
