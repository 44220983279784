import { Conversation } from "deblank-api-types"

export const getLastMessagesInConversation = (params: {
	conversation: Conversation,
}) => {
	const numberOfMessages = 5
	const messages = Object.values(params.conversation.messages).slice(-numberOfMessages)

	const outputsIdsFromIterations = messages
		.flatMap((message) => (message.outputsIdsByIterations))
		.map((iteration) => (iteration.outputId))
	const outputsIdsFromUserMessages = messages
		.flatMap((message) => (message.userMessage && message.userMessage.idRefs
			? [message.userMessage.idRefs,]
			: []
		)).flat()
		.map((idRef) => (idRef.widgetId))
	const uniqueOutputsIds = new Set([...outputsIdsFromIterations, ...outputsIdsFromUserMessages,])

	const outputs = Array.from(uniqueOutputsIds).map((outputId) => (params.conversation.outputs![outputId]))

	return ({
		messages: messages,
		outputs: outputs,
	})
}
