import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedFonts.module.scss"
import { useRecoilValue } from "recoil"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import classNames from "classnames"
import Actions from "../../../Actions/Actions"
import usePresetActions from "../../../Actions/usePresetActions"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { CommonWidgetFontsItemProps } from "../WidgetFontsTypes"
import { FontDetailsByPairing, formatFontNamePairing } from "../_helper/FontDetailsByPairing"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExportHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"


const WidgetPinnedFonts = (props: CommonWidgetFontsItemProps) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)
	const ref = React.useRef<HTMLDivElement>(null)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.widgetItemDetails.slug,
	})

	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))

	const getFontNames = formatFontNamePairing({
		pairingFontName: fontPairingDetails?.metadata.name,
		mainFontName: fontMainDetails?.metadata.name,
		pairing: props.metadata.pairing,
	})

	const handleExport = () => {
		setTimeout(() => {
			ExportHelper.fromTemplate(ref)
			trackConversationEvent({
				eventName: "Export",
				widgetType: props.widgetType,
				responseId: props.idRef.messageId,
				outputId: props.idRef.widgetId,
			})
		}, 200)
	}

	const handleGetFont = (url: string, fontName: string) => {
		window.open(url, "_blank")
		trackConversationEvent({
			eventName: "ClickGetFont",
			fontName: fontName,
			responseId: props.idRef.messageId,
		})
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: fontPairingDetails ? "Get title font" : "Get font",
			icon: LinkIcon,
			onClick: () => handleGetFont(fontMainDetails!.metadata.linkToVendor, fontMainDetails!.metadata.name),
		},
	]

	if (fontPairingDetails) {
		actionList.push({
			text: "Get body font",
			icon: LinkIcon,
			onClick: () => handleGetFont(fontPairingDetails.metadata.linkToVendor, fontPairingDetails.metadata.name),
		})
	}

	actionList.push({
		text: "Export",
		icon: ExportIcon,
		onClick: handleExport,
	})

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	return (<>
		<article className={wrapperClasses}
			ref={ref}
			id={
				ScreenshotIdManager.assignId({
					widgetUiSection: WidgetUiSectionId.Pin,
					idRef: props.idRef,
				})
			}
		>
			<span className={Styles.font_name}>
				{getFontNames}
			</span>
			{fontMainDetails
				&& <h3 className={Styles.title}
					style={fontMainDetails.fontCSSProperties}>
					{props.metadata.title}
				</h3>
			}
			{fontPairingDetails
				&& <p className={Styles.paragraph}
					style={fontPairingDetails.fontCSSProperties}>
					{props.metadata.description}
				</p>
			}
			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinItem",
				}}
			/>
		</article>
	</>
	)
}

export default WidgetPinnedFonts
