import React, { useEffect, useRef, useState } from "react"
import Styles from "./ConversationLoadManager.module.scss"
import { trpc } from "@/providers/TRPCProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { settersFontCache } from "@/recoil/FontCache/Setters"
import { Conversation } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { extractSlugsFromWidgets } from "./ConversationLoadManagerHelper"
import * as Sentry from "@sentry/react"
import GradientLoadingAnimation from "@/components/GradientLoadingAnimation/GradientLoadingAnimation"
import Spinner, { SpinnerSize } from "@/components/Spinner/Spinner"
import Button from "@/components/Buttons/Button/Button"
import ImageManager, { FetchParams } from "@/utils/ImageManager"

const ConversationStateLoaderManager = () => {

	const [hasError, setHasError,] = useState(false)
	const isFetching = useRef(false)

	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const setConversationById = settersConversations.useSetConversationById()
	const addSlugsToPendingSlugs = settersFontCache.useAddSlugsToPendingSlugs()
	const setLoadConversationState = settersConversations.useSetLoadConversationState()

	const getConversationById = trpc.assistant.conversation.getConversationById.useMutation()
	const getSignedUrl = trpc.assistant.imageStorage.getImageSignedUrl.useMutation()

	const handleFetchImage = async (params: FetchParams) => {
		try {
			return await getSignedUrl.mutateAsync({
				imageFileName: params.imageFileName,
				conversationId: params.conversationId,
			})
		} catch (error) {
			Sentry.captureException(error)
			throw error
		}
	}

	useEffect(() => {
		ImageManager.setFetchImageUrlFunction(handleFetchImage)
	}, [])

	useEffect(() => {

		if (loadConversationState && activeConversationId && !isFetching.current) {
			isFetching.current = true
			loadConversationById({
				conversationId: activeConversationId,
			})
		}

	}, [
		loadConversationState,
		activeConversationId,
	])

	const handleRetryLoad = () => {
		setHasError(false)
		loadConversationById({
			conversationId: activeConversationId!,
		})
	}

	const loadConversationById = async (params: {
		conversationId: string,
	}) => {
		try {
			const conversation = await getConversationById.mutateAsync({
				conversationId: params.conversationId,
			})

			if (conversation && conversation.frontEndState) {
				const fetchedConversationParsed = JSON.parse(conversation.frontEndState) as Conversation

				if (fetchedConversationParsed.outputs) {
					const slugs = extractSlugsFromWidgets({
						widgets: fetchedConversationParsed.outputs,
					})
					addSlugsToPendingSlugs(slugs || [])
				}

				setConversationById({ conversation: fetchedConversationParsed, })
				return setLoadConversationState(false)
			} else {
				throw new Error("Conversation not has frontEndState")
			}
		}
		catch (error) {
			setHasError(true)
			const errorMessage = "Error loading conversation"
			console.error(errorMessage, error)
			Sentry.captureException(error)
			throw new Error(errorMessage)
		} finally {
			isFetching.current = false
		}
	}


	return (
		<section className={Styles.wrapper_gradient_animation}>
			{
				(hasError)
					? <article className={Styles.content_error_message}>
						<span className={Styles.error_message}>
							Error loading conversation
						</span>

						<Button type="button"
							onClick={handleRetryLoad}
							customStyles={{
								variant: "ghost",
								size: "small",
							}}
						>
							Retry
						</Button>
					</article>
					: <>
						<GradientLoadingAnimation />
						<span className={Styles.content_spinner}>
							<Spinner size={SpinnerSize.Small} />
						</span>
					</>
			}
		</section>
	)
}

export default ConversationStateLoaderManager
