import React, { useState } from "react"
import Styles from "./ImageCard.module.scss"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"
import Spinner, { SpinnerSize } from "../Spinner/Spinner"
import ReloadIcon from "deblank-common/src/assets/images/icon-library/regenerate.svg"
import ImageManager from "@/utils/ImageManager"
import { useGetData } from "@/hooks/getData/useGetData"
import * as Sentry from "@sentry/react"
import { simplifyAspectRatio } from "@/utils/simplifyAspectRatio"
import classNames from "classnames"

type Props = {
	imageFileName: string,
	altText: string,
}

const ImageFromStore = (props: Props) => {

	const [imageFailedToLoad, setImageFailedToLoad,] = useState<boolean>(false)
	const [imageLoaded, setImageLoaded,] = useState<boolean>(false)

	const conversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const imageClasses = classNames(
		Styles.image,
		{
			[Styles.image_loaded]: imageLoaded,
		}
	)

	const memoizedQuery = React.useMemo(
		() => ({
			imageFileName: props.imageFileName,
			conversationId: conversationId!,
			key: props.imageFileName,
		}),
		[props.imageFileName, conversationId,]
	)

	const imageSync = useGetData({
		query: memoizedQuery,
		getData: ImageManager.getImageUrl,
	})

	const handleDisableClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		e.preventDefault()
	}

	const handleRetry = () => {
		setImageFailedToLoad(false)
		imageSync.mutate()
	}


	if (imageSync.loading) {
		return (
			<div className={Styles.wrapper_loading}>
				<Spinner size={SpinnerSize.Small} />
			</div>
		)
	}

	if (imageSync.error) {

		Sentry.captureMessage("Failed to load image from image")

		return (
			<div className={Styles.wrapper_loading}
				aria-hidden="true"
				onClick={handleDisableClick}
			>
				<span className={Styles.retry_button}
					onClick={handleRetry}
					aria-hidden="true">
					<ReloadIcon />
				</span>
			</div>
		)
	}

	if (imageFailedToLoad) {
		return (
			<div className={Styles.wrapper_loading}
				aria-hidden="true"
				onClick={handleDisableClick}
			>
				<span>
					Image failed to load
				</span>
			</div>
		)
	}

	if (!imageSync.data) {
		return null
	}

	const aspectRatio: React.CSSProperties | null = imageSync.data.imageHeight
		? { aspectRatio: simplifyAspectRatio({ width: imageSync.data.imageWidth, height: imageSync.data.imageHeight, }), }
		: null


	return (
		<img className={imageClasses}
			src={imageSync.data.signedUrl}
			alt={props.altText}
			style={aspectRatio ? aspectRatio : undefined}
			onError={() => { setImageFailedToLoad(true) }}
			onLoad={() => { setImageLoaded(true) }}
		/>
	)
}

export default ImageFromStore
