import { Output, OutputsById } from "deblank-api-types"
import { selectorFamily, selector } from "recoil"
import { atomConversationsRecord } from "../Atom"
import { prefixRecoilKey } from "./Common"

const outputById = selectorFamily<Output, string>({
	key: prefixRecoilKey("outputById"),
	get: (id) => ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		const currentConversation = conversations[activeConversationId!]!

		return currentConversation.outputs![id]
	},
})

const getOutputListInCurrentConversation = selector<OutputsById | null>({
	key: prefixRecoilKey("getOutputListInCurrentConversation"),
	get: ({ get, }) => {
		const { activeConversationId, conversations, } = get(atomConversationsRecord)
		if (!activeConversationId) {
			return null
		}
		const currentConversation = conversations[activeConversationId]

		return currentConversation && currentConversation.outputs
			? currentConversation.outputs
			: null
	},
})


export const selectorsWidgets = {
	outputById,
	getOutputListInCurrentConversation,
}
