import { trpc } from "@/providers/TRPCProvider"
import { ConversationMetadata } from "deblank-api-types"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { useRecoilState } from "recoil"

export const useConversationHandlers = () => {

	const createNewConversation = trpc.assistant.conversation.createConversation.useMutation()

	const addNewConversation = settersConversations.useAddNewConversation()
	const clearCurrentConversationId = settersConversations.useClearCurrentConversationId()
	const setShowNewChat = settersConversations.useSetShowNewChat()
	const setIsOpenPinned = settersUiTemporary.useSetIsOpenPinned()
	const [_, setPrompt,] = useRecoilState(selectorsUITemporary.userPromptState)

	const onCreateNewConversation = async (params: {
		prompt: string,
	}) => {
		setPrompt("")
		clearCurrentConversationId()
		setShowNewChat({ showNewChatView: false, })
		setIsOpenPinned({ isOpen: false, })

		const metadata: ConversationMetadata = {
			conversationName: params.prompt,
			outputs: [],
			messages: [],
		}
		const newConversation = await createNewConversation.mutateAsync({
			metadata: metadata,
		})

		addNewConversation({
			userMessage: params.prompt,
			conversationId: newConversation.id,
			createDate: newConversation.createdAt,
		})
	}

	return ({
		onCreateNewConversation,
	})
}
