import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedColors.module.scss"
import Actions from "../../../Actions/Actions"
import classNames from "classnames"
import usePresetActions from "@/components/Actions/usePresetActions"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { CommonWidgetColorItemProps } from "../WidgetColorsTypes"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExportHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


const WidgetPinnedColors = (props: CommonWidgetColorItemProps) => {

	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const ref = React.useRef<HTMLDivElement>(null)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)

	const setColorPlayground = settersCommon.useSetColorPlaygroundIdRef()
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleExport = () => {
		ExportHelper.fromTemplate(ref)
		trackConversationEvent({
			eventName: "Export",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.widgetType,
		})
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Export",
			icon: ExportIcon,
			onClick: handleExport,
		},
	]

	const wrapperClasses = classNames(
		Styles.palette,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	const handleOpenPlayground = () => {
		trackConversationEvent({
			eventName: "OpenPlayground",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setColorPlayground({ idRef: props.idRef, })
	}

	return (
		<div className={wrapperClasses}
			id={
				ScreenshotIdManager.assignId({
					widgetUiSection: WidgetUiSectionId.Pin,
					idRef: props.idRef,
				})
			}
		>
			<div ref={ref} className={Styles.palette_wrapper}>
				{
					props.widgetItemDetails.palette.map((color, index) => (
						<button key={`${index}-${color}`}
							onClick={handleOpenPlayground}
							className={Styles.palette_item}
							style={{ "--palette_item": color, } as React.CSSProperties}
							disabled={areMessagesLoading || isSavingNewConversationName}
						/>
					))
				}
			</div>

			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinColor",
				}}
			/>
		</div>
	)
}

export default WidgetPinnedColors
