/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from "react"
import Styles from "./FontCard.module.scss"
import { selectorsFontCache } from "@/recoil/FontCache/Selectors"
import { useRecoilValue } from "recoil"
import Actions from "../../../../Actions/Actions"
import { DisplayWidgetType, FontsMetadata, IdRefWidgetWithPaginationItem, } from "deblank-api-types"
import usePresetActions from "../../../../Actions/usePresetActions"
import { FontDetailsByPairing, formatFontNamePairing } from "../../_helper/FontDetailsByPairing"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { ExportHelper } from "@/utils/exportHelper"
import { ScreenshotIdManager, WidgetUiSectionId } from "@/utils/ScreenshotIdManager"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


type SlugWithId = {
	id: string,
	slug: string,
}

type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	font: SlugWithId,
	metadata: FontsMetadata,
	widgetType: DisplayWidgetType,
}

const FontCard = (props: Props) => {
	const ref = React.useRef<HTMLDivElement>(null)
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const fontSlugsByPairing = FontDetailsByPairing({
		pairing: props.metadata.pairing,
		baseFontSlug: props.metadata.baseFont || null,
		fontSlug: props.font.slug,
	})

	const setFontPlayground = settersCommon.useSetFontPlaygroundIdRef()

	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)
	const fontMainDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.titleSlug))
	const fontPairingDetails = useRecoilValue(selectorsFontCache.fontFromCache(fontSlugsByPairing.paringSlug || undefined))

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const getFontNames = formatFontNamePairing({
		pairingFontName: fontPairingDetails?.metadata.name,
		mainFontName: fontMainDetails?.metadata.name,
		pairing: props.metadata.pairing,
	})

	const handleGoToFontVendor = async (url: string) => {
		fontMainDetails && window.open(url, "_blank")
		await trackConversationEvent({
			eventName: "ClickGetFont",
			fontName: fontMainDetails!.metadata.name,
			responseId: props.idRef.messageId,
		})
	}

	const handleExport = () => {
		setTimeout(() => {
			ExportHelper.fromTemplate(ref)
			trackConversationEvent({
				eventName: "Export",
				widgetType: DisplayWidgetType.Fonts,
				responseId: props.idRef.messageId,
				outputId: props.idRef.widgetId,
			})
		}, 200)
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: fontPairingDetails ? "Get title font" : "Get font",
			icon: LinkIcon,
			onClick: () => handleGoToFontVendor(fontMainDetails!.metadata.linkToVendor),
		},
	]

	if (fontPairingDetails) {
		actionList.push({
			text: "Get body font",
			icon: LinkIcon,
			onClick: () => handleGoToFontVendor(fontPairingDetails.metadata.linkToVendor),
		})
	}

	actionList.push({
		text: "Export",
		icon: ExportIcon,
		onClick: handleExport,
	})

	const handleOpenModal = () => {
		if (!areMessagesLoading && !isSavingNewConversationName) {
			trackConversationEvent({
				eventName: "OpenPlayground",
				widgetType: props.widgetType,
				responseId: props.idRef.messageId,
			})
			setFontPlayground({
				idRef: props.idRef,
			})
		}
	}

	return (
		<article id={
			ScreenshotIdManager.assignId({
				widgetUiSection: WidgetUiSectionId.Conversation,
				idRef: props.idRef,
			})
		}
			ref={ref}
			className={`${Styles.wrapper} actions_handler`}
			onClick={handleOpenModal}
		>
			<div className={Styles.content_fonts_names_actions}>
				<span className={Styles.fonts_name}>
					{getFontNames}
				</span>

				<div onClick={e => {
					e.preventDefault()
					e.stopPropagation()
				}}>
					<Actions
						actionList={actionList}
						customStyles={{
							actionType: "fonts",
						}}
					/>
				</div>
			</div>

			{fontMainDetails
				&& <h3 style={fontMainDetails.fontCSSProperties}
					className={Styles.title_text}>
					{props.metadata.title}
				</h3>
			}

			{fontPairingDetails
				&& <p style={fontPairingDetails.fontCSSProperties}
					className={Styles.description_text}>
					{props.metadata.description}
				</p>
			}

		</article>
	)
}

export default FontCard
