import { domToPng } from "modern-screenshot"
import JSZip from "jszip"

export const fromImage = async (url: string) => {
	try {
		const date = new Intl.DateTimeFormat("es-ES", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		}).format(new Date())
			.replace(/\//g, "-")
		const response = await fetch(url, { method: "GET", })
		if (!response.ok) {
			throw new Error("Failed to download image")
		}

		const arrayBuffer = await response.arrayBuffer()
		const blob = new Blob([arrayBuffer,], { type: "image/png", })

		const link = document.createElement("a")
		link.href = URL.createObjectURL(blob)
		link.download = `deblank_${date}.png`
		document.body.appendChild(link)
		link.click()

		URL.revokeObjectURL(link.href)
		link.remove()
	} catch (e) {
		console.error(e)
		throw new Error(`Error while trying to export the image from ${url}`)
	}
}

export const fromTemplate = async (ref: React.RefObject<HTMLDivElement>) => {
	try {
		const date = new Intl.DateTimeFormat("es-ES", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		}).format(new Date())
			.replace(/\//g, "-")
		const dataUrl = await domToPng(ref.current!, {
			quality: 1,
			backgroundColor: "white",
		})
		const link = document.createElement("a")
		link.download = `deblank_${date}.png`
		link.href = dataUrl
		link.click()
	} catch (e) {
		console.error(e)
		throw new Error(`Error while trying to export the image from ${ref.current}`)
	}
}

export const urlFromTemplate = async (ref: HTMLElement) => {
	try {
		const dataUrl = await domToPng(ref, {
			quality: 1,
			backgroundColor: "white",
		})
		return dataUrl
	} catch (e) {
		console.error(e)
		throw new Error("Error while trying to export the image from template")
	}
}

export const fromBase64 = (base64: string) => {
	try {
		const date = new Intl.DateTimeFormat("es-ES", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		}).format(new Date())
			.replace(/\//g, "-")
		const link = document.createElement("a")
		link.href = base64
		link.download = `deblank_${date}.png`
		document.body.appendChild(link)
		link.click()
		link.remove()
	} catch (e) {
		console.error(e)
		throw new Error("Error while trying to export the image from base64")
	}
}

const urlToBlob = async (url: string) => {
	const response = await fetch(url, { method: "GET", })
	if (!response.ok) {
		console.error(`Failed to download image from ${url}: ${response.statusText}`)
		return null
	}

	const arrayBuffer = await response.arrayBuffer()
	return new Blob([arrayBuffer,], { type: "image/png", })
}

const zip = async (urls: string[]) => {
	try {
		const zip = new JSZip()
		const date = new Intl.DateTimeFormat("es-ES", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		}).format(new Date())
			.replace(/\//g, "-")

		for (let i = 0; i < urls.length; i++) {
			const imageBlob = await urlToBlob(urls[i])
			imageBlob && zip.file(`output_1_${i}.png`, imageBlob)
		}

		const zipData = await zip.generateAsync({
			type: "blob",
			streamFiles: true,
		})

		const link = document.createElement("a")
		link.href = window.URL.createObjectURL(zipData)
		link.download = `deblank_${date}.zip`
		link.click()
	} catch (e) {
		console.error(e)
	}
}


export const ExportHelper = {
	fromImage,
	fromTemplate,
	urlFromTemplate,
	fromBase64,
	zip,
}
