import React from "react"
import NewUserMessage from "./NewUserMessage/NewUserMessage"
import PromptSuggestions from "./Suggestions/PromptSuggestions"

type Props = {
	showMessageforNewUser: boolean,
}

const InitialMessage = (props: Props) => {
	return (
		props.showMessageforNewUser
			? <NewUserMessage />
			: <PromptSuggestions />
	)
}

export default InitialMessage
