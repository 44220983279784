import React, { useContext } from "react"
import Styles from "./PromptSuggestions.module.scss"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import { suggestionsList } from "./utils/suggestionsList"
import Button from "@/components/Buttons/Button/Button"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { useConversationHandlers } from "@/hooks/useConversationHandlers"

const PromptSuggestions = () => {

	const conversationHandlers = useConversationHandlers()

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleClickedSuggestion = async (suggestion: string) => {
		await conversationHandlers.onCreateNewConversation({
			prompt: suggestion,
		})
		await trackConversationEvent({
			eventName: "SubmitInitialRecommendation",
			prompt: suggestion,
		})
	}

	return (
		<section className={Styles.container}>
			<div className={Styles.star}><StarIcon /></div>
			<section className={Styles.suggestions_container}>
				{suggestionsList.map((suggestion, index) => (
					<div key={index}>
						<Button
							onClick={() => handleClickedSuggestion(suggestion)}
							type="button"
							customStyles={{
								variant: "suggestion",
							}}
						>
							{suggestion}
						</Button>
					</div>
				))}
			</section>
		</section>
	)
}

export default PromptSuggestions
