import React, { useContext, useRef, useState, } from "react"
import Styles from "./CurrentConversation.module.scss"
import { useRecoilValue } from "recoil"
import PromptToolbar from "./PromptToolbar/PromptToolbar"
import classNames from "classnames"
import { AnimatePresence, m, } from "framer-motion"
import { UserContext } from "@/providers/UserProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import InitialMessage from "./InitialMessage/InitialMessage"
import MessageList from "./MessageList/MessageList"
import TermsMessage from "./TermsMessage/TermsMessage"
import { promptToolbarProps } from "./CurrentConversationAnimation"
import InstagramFooter from "./AlphaUsersComponents/InstagramFooter/InstagramFooter"

const CurrentConversation = () => {
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const chatAmount = useRecoilValue(selectorsConversations.chatAmount)
	const userContext = useContext(UserContext)
	const chatContainerRef = useRef<null | HTMLDivElement>(null)
	const [showInstagramFooter, setShowInstagramFooter,] = useState(true)

	const spaceChatClasses = classNames(
		Styles.space_chat_area,
		{
			[Styles.not_empty]: !(showNewChat),
		}
	)

	const conversationClases = classNames(
		Styles.conversation_container,
		{
			[Styles.not_new_chat]: !(showNewChat),
		}
	)

	const showInitialMessage = userContext.isNewUser && chatAmount === 0

	return (
		<section className={conversationClases}>
			<section className={spaceChatClasses}
				key={activeConversationId}
				ref={chatContainerRef}
			>
				{
					showNewChat
						? <InitialMessage
							showMessageforNewUser={showInitialMessage}
						/>
						: <MessageList
							htmlConversationContainerRef={chatContainerRef}
						/>
				}
			</section >

			<section className={Styles.space_prompt_toolbar}>
				<section className={Styles.wrapper_toolbar}>
					<section className={Styles.content_toolbar}>
						<AnimatePresence>
							{!showInitialMessage
								&& <m.div {...promptToolbarProps}>
									<PromptToolbar />
								</m.div>
							}
						</AnimatePresence>
					</section>
				</section>

				{showNewChat && <TermsMessage />}
			</section>
			<AnimatePresence>
				{showInstagramFooter && <InstagramFooter onClose={() => setShowInstagramFooter(false)} />}
			</AnimatePresence>
		</section>
	)
}

export default CurrentConversation
