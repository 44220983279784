import {
	DisplayWidgetType, PendingNotificationCreate, UserMessage
} from "deblank-api-types"
import { useContext } from "react"
import { TrackConversationEventContext } from "../../TrackConversationEventProvider"
import { useRecoilValue } from "recoil"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"


export const useTrackPendingMessages = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const messageData = useRecoilValue(selectorsMessages.getMessageDataByMessageId)
	const outputList = useRecoilValue(selectorsWidgets.getOutputListInCurrentConversation)

	if (!outputList) {
		return {
			onTrackEvents: async () => { },
		}
	}

	const handleTrackEvents = async () => {
		await trackConversationEvent({
			eventName: "SubmitPrompt",
			prompt: messageData.userMessage?.message || "",
			outputsReferences: messageData.userMessage?.idRefs?.map((outputId) => outputId.widgetId) || [],
			responseId: messageData.id,
			widgetTypes: messageData.outputsIdsByIterations.flatMap((iteration) => outputList[iteration.outputId].type),
		})
		messageData.outputsIdsByIterations.forEach((iteration) => {
			const output = outputList[iteration.outputId]
			switch (output.type) {
				case DisplayWidgetType.Text: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.ColorsExplanations: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						palette: output.data.results.flatMap((result) => result.palette),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.FontsExplanations: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						fontsSlugs: output.data.results.flatMap((result) => result.slug),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.Colors: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						palettes: output.data.pages.flatMap((page) => page.results.map((result) => result.palette)),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.Fonts: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						fontsSlugs: output.data.pages.flatMap((page) => page.results.map((result) => result.slug)),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.MockupColors: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						mockupsIds: output.data.pages.flatMap((page) => page.results.map((result) => result.mockupId)),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.MockupFonts: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						mockupsIds: output.data.pages.flatMap((page) => page.results.map((result) => result.mockupId)),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.Images: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						urls: output.data.pages.flatMap((page) => page.results.map((result) => result.imageFileName)),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				case DisplayWidgetType.Searches: {
					trackConversationEvent({
						eventName: "Response",
						widgetType: output.type,
						images: output.data.results.flatMap((result) => ({
							url: result.imageUrl,
							searchFrom: result.searchFrom,
						})),
						prompt: messageData.userMessage!.message,
						responseId: messageData.id,
					})
					break
				}
				default: {
					break
				}
			}
		})
	}


	return {
		onTrackEvents: handleTrackEvents,
	}
}


export const useTrackPendingNotifications = () => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const handleTrackEvents = (pendingNotifications: (PendingNotificationCreate & { userMessage: UserMessage | null, })[]) => {

		pendingNotifications.forEach((notification) => {
			notification.outputs.forEach((output) => {
				switch (output.type) {
					case DisplayWidgetType.Colors: {
						trackConversationEvent({
							eventName: "Response",
							widgetType: output.type,
							prompt: notification.userMessage?.message || "",
							palettes: output.data.pages.flatMap((page) => page.results.map((result) => result.palette)),
						})
						break
					}
					case DisplayWidgetType.MockupColors: {
						trackConversationEvent({
							eventName: "Response",
							widgetType: output.type,
							prompt: notification.userMessage?.message || "",
							mockupsIds: output.data.pages.flatMap((page) => page.results.map(result => result.mockupId)),
						})
						break
					}
					case DisplayWidgetType.MockupFonts: {
						trackConversationEvent({
							eventName: "Response",
							widgetType: output.type,
							prompt: notification.userMessage?.message || "",
							mockupsIds: output.data.pages.flatMap((page) => page.results.map(result => result.mockupId)),
						})
						break
					}
					default: {
						break
					}
				}
			})
		})


	}

	return {
		onTrackEvents: handleTrackEvents,
	}
}
