import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedMockups.module.scss"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import classNames from "classnames"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import { CommonWidgetMockupsItemProps } from "../WidgetMockupsTypes"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExportHelper } from "@/utils/exportHelper"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import ImageManager from "@/utils/ImageManager"
import { useRecoilValue } from "recoil"

const WidgetPinnedMockups = (props: CommonWidgetMockupsItemProps) => {

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)
	const setImageModal = settersCommon.useSetImageModalIdRef()
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const handleExport = async () => {
		const getSignedUrl = await ImageManager.getImageUrl({
			imageFileName: props.widgetItemDetails.imageFileName,
			conversationId: activeConversationId!,
		})

		ExportHelper.fromImage(getSignedUrl.signedUrl)

		trackConversationEvent({
			eventName: "Export",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const actionList: widgetAction[] = [
		pinPresetAction,
		sendToChatPresetAction,
		{
			text: "Export",
			icon: ExportIcon,
			onClick: handleExport,
		},
		{
			text: "Open",
			icon: OpenIcon,
			onClick: handleOpenModal,
		},
	]

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	return (
		<figure className={wrapperClasses}>
			<button className={Styles.image}
				onClick={handleOpenModal}
			>
				<ImageByWidgetType widgetType={props.widgetType}
					widgetDetails={props.widgetItemDetails}
				/>
			</button>
			<Actions
				actionList={actionList}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinItem",
				}}
			/>
		</figure>
	)
}

export default WidgetPinnedMockups
