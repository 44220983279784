import { trpc } from "@/providers/TRPCProvider"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"

const base64ToArrayBuffer = (base64: string) => {
	const binaryString = atob(base64)
	const bytes = new Uint8Array(binaryString.length)
	for (let i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	return bytes.buffer
}

export const useUploadImage = () => {
	const getuploadImageUrl = trpc.assistant.imageStorage.getSignedUrlToUploadImage.useMutation().mutateAsync
	const conversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const uploadImage = async (base64: string) => {

		const imageUploadUrl = await getuploadImageUrl({
			fileExtension: "png",
			conversationId: conversationId!,
			clientOrigin: window.location.origin,
		})


		const buf = base64ToArrayBuffer(base64.split(",")[1])

		const response = await fetch(imageUploadUrl.url, {
			method: "PUT",
			headers: {
				"Content-Type": "image/png",
				"x-goog-content-length-range": "0,20000000",
			},
			body: buf,
		})

		if (response.ok) {
			return imageUploadUrl.imageId
		} else {
			throw new Error("Error uploading image")
		}

	}

	return { uploadImage, }

}
