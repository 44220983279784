import React, { useContext, useState } from "react"
import Styles from "./ImageCard.module.scss"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import Actions from "@/components/Actions/Actions"
import usePresetActions from "@/components/Actions/usePresetActions"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { ExportHelper } from "@/utils/exportHelper"
import classNames from "classnames"
import { IdRefWidgetWithPaginationItem, DisplayWidgetType, SearchResponse, ImageResponse, SearchFrom } from "deblank-api-types"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"
import ImageManager from "@/utils/ImageManager"
import { useRecoilValue } from "recoil"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


type Props = {
	idRef: IdRefWidgetWithPaginationItem,
	type: DisplayWidgetType,
	widgetData: SearchResponse | ImageResponse,
}

const ImageCard = (
	props: Props
) => {
	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const { trackConversationEvent, } = useContext(TrackConversationEventContext)

	const setImageModal = settersCommon.useSetImageModalIdRef()
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)

	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.type,
	})

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const handleExportFromSearch = () => {
		const widget = props.widgetData as SearchResponse
		ExportHelper.fromImage(widget.imageUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Searches,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})

	}

	const handleExportFromGeneratedImage = async () => {
		const widget = props.widgetData as ImageResponse

		const getSignedUrl = await ImageManager.getImageUrl({
			imageFileName: widget.imageFileName,
			conversationId: activeConversationId!,
		})

		ExportHelper.fromImage(getSignedUrl.signedUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const getActions = () => {
		const searchDetails = props.type === DisplayWidgetType.Searches && props.widgetData as SearchResponse
		const actionList: widgetAction[] = [
			pinPresetAction,
		]

		if (searchDetails) {
			const newActions = [
				{
					text: "Go to site",
					icon: LinkIcon,
					onClick: () => {
						trackConversationEvent({
							eventName: "ClickImageLink",
							widgetType: DisplayWidgetType.Images,
							responseId: props.idRef.messageId,
						})
						window.open(searchDetails.link, "_blank")
					},
				},
			]
			if (searchDetails.searchFrom === SearchFrom.Freeflo) {
				newActions.push(...[{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromSearch(),
				}, {
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
			} else {
				newActions.push({
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				})
			}

			actionList.push(...newActions)

		} else {
			actionList.push(...[
				sendToChatPresetAction,
				{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromGeneratedImage(),
				},
				{
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
		}
		return actionList
	}


	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.actions_open]: actionsOpen,
		}
	)

	return (
		<figure
			className={wrapperClasses}
		>
			<button type="button"
				onClick={handleOpenModal}
				className={Styles.image}
			>
				<ImageByWidgetType widgetType={props.type}
					widgetDetails={props.widgetData}
				/>
			</button>

			<Actions
				actionList={getActions()}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "images",
				}}
			/>
		</figure>
	)
}

export default ImageCard
