import React from "react"
import Styles from "./AssitantLayout.module.scss"
import Header from "../../../../components/Header/Header"
import ProtectedRoute from "@/components/ProtectedRoute/ProtectedRoute"
import { AnimatePresence, m } from "framer-motion"
import {
	containerGridMotionProps, sidebarMotionProps,
	pinnedPanelMotionProps, opacityContainerMotionProps,
	debugPanelMotionProps,
} from "./Animations"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import SideBarIcon from "deblank-common/src/assets/images/icon-library/sidebar.svg"
import NewChatIcon from "deblank-common/src/assets/images/icon-library/new-chat.svg"
import HistoryIcon from "deblank-common/src/assets/images/icon-library/history.svg"
import PinIcon from "deblank-common/src/assets/images/icon-library/pin.svg"
import { useRecoilValue } from "recoil"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { settersUiTemporary } from "@/recoil/Ui/Temporary/Setters"
import { EnvHelperClient } from "@/utils/envHelper"
import ModalWrapper from "@/components/ModalWrapper/ModalWrapper"
import { selectorsCommon } from "@/recoil/ConversationsRecord/Selectors/Common"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { selectorsAssistantResponseTools } from "@/recoil/ConversationsRecord/Selectors/AssistantResponseTools"
import { getRouteApi } from "@tanstack/react-router"


type Props = {
	sidebar: React.ReactNode,
	currentConversation: React.ReactNode,
	pinned: React.ReactNode,
	debug: React.ReactNode,
	colorsPlayground: React.ReactNode,
	fontsPlayground: React.ReactNode,
	imageModal: React.ReactNode,
}

const AssistantLayout = (props: Props) => {

	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isDebugPanelOpen = useRecoilValue(selectorsUITemporary.isDebugPanelOpen)
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const showNewChat = useRecoilValue(selectorsConversations.showNewChat)
	const existDebugData = useRecoilValue(selectorsAssistantResponseTools.showDebugPanel)
	const isColorsPlaygroundOpen = !!useRecoilValue(selectorsCommon.colorPlaygroundWidget)
	const isFontsPlaygroundOpen = !!useRecoilValue(selectorsCommon.fontPlaygroundWidget)
	const isImageModalOpen = !!useRecoilValue(selectorsCommon.imageModalWidget)

	const toggleSidebar = settersUiTemporary.useToggleIsOpenSidebar()
	const togglePinned = settersUiTemporary.useToggleIsOpenPinned()
	const toggleDebugPanel = settersUiTemporary.useToggleIsOpenDebugPanel()
	const setShowNewChat = settersConversations.useSetShowNewChat()
	const setIsOpenPinned = settersUiTemporary.useSetIsOpenPinned()

	const routeApi = getRouteApi("/")
	const searchParams = routeApi.useSearch()

	const userHasDebugAccess = existDebugData && (searchParams.useDebug || EnvHelperClient.isLocalDev)

	const handleNewChat = () => {
		setIsOpenPinned({ isOpen: false, })
		setShowNewChat({ showNewChatView: true, })
	}

	return (
		<ProtectedRoute>
			<main>
				<Header />

				<m.section className={Styles.container_grid}
					{...containerGridMotionProps({
						showSidebar: isSidebarOpen,
						showRightPanel: (isPinnedListOpen || isDebugPanelOpen),
					})}
				>
					<AnimatePresence mode="popLayout">
						{!isSidebarOpen
							&& <m.div className={Styles.actions_container_left}
								{...opacityContainerMotionProps}
							>
								<ButtonIcon
									type="button"
									onClick={toggleSidebar}
									iconSVGComponent={SideBarIcon}
									customStyles={{
										variant: "ghost",
									}}
								/>
								<ButtonIcon
									type="button"
									onClick={handleNewChat}
									disabled={someMessageIsLoading || showNewChat}
									iconSVGComponent={NewChatIcon}
									customStyles={{
										variant: "ghost",
										background: true,
									}}
								/>
							</m.div>}
					</AnimatePresence>

					<AnimatePresence mode="popLayout">
						{!isPinnedListOpen && <m.div
							className={Styles.actions_container_right}
							{...opacityContainerMotionProps}
						>
							{userHasDebugAccess && (
								<ButtonIcon
									type="button"
									onClick={toggleDebugPanel}
									iconSVGComponent={HistoryIcon}
									customStyles={{
										variant: "ghost",
									}}
								/>
							)}

							<ButtonIcon
								type="button"
								onClick={togglePinned}
								iconSVGComponent={PinIcon}
								customStyles={{
									variant: "ghost",
								}}
							/>
						</m.div>}
					</AnimatePresence>

					<AnimatePresence mode="popLayout">
						{isSidebarOpen
							&& <>
								<m.section
									className={Styles.container_sidebar}
									{...sidebarMotionProps}
								>
									{props.sidebar}
								</m.section>

								<m.div className={Styles.mobile_overlay}
									onClick={toggleSidebar}
									{...opacityContainerMotionProps} />
							</>
						}
					</AnimatePresence>

					<section className={Styles.container_chat}>
						{props.currentConversation}
					</section>

					<AnimatePresence mode="popLayout">
						{(isPinnedListOpen)
							&& <>
								<m.section
									className={Styles.container_pinned}
									{...pinnedPanelMotionProps}
								>
									{props.pinned}
								</m.section>
								<m.div className={Styles.mobile_overlay}
									onClick={togglePinned}
									{...opacityContainerMotionProps} />
							</>
						}
					</AnimatePresence>

					<AnimatePresence mode="popLayout">
						{(isDebugPanelOpen && userHasDebugAccess)
							&& <>
								<m.section
									className={Styles.container_debug}
									{...debugPanelMotionProps}
								>
									{props.debug}
								</m.section>
								<m.div className={Styles.mobile_overlay}
									onClick={toggleDebugPanel}
									{...opacityContainerMotionProps} />
							</>
						}
					</AnimatePresence>
				</m.section>

				<AnimatePresence>
					{isColorsPlaygroundOpen
						&& <ModalWrapper key="colors-playground">
							{props.colorsPlayground}
						</ModalWrapper>
					}
				</AnimatePresence>

				<AnimatePresence>
					{isFontsPlaygroundOpen
						&& <ModalWrapper key="fonts-playground">
							{props.fontsPlayground}
						</ModalWrapper>
					}
				</AnimatePresence>

				<AnimatePresence>
					{isImageModalOpen
						&& <ModalWrapper key="image-modal">
							{props.imageModal}
						</ModalWrapper>
					}
				</AnimatePresence>

			</main>
		</ProtectedRoute>
	)
}

export default AssistantLayout
