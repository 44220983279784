import { useTrackPendingNotifications } from "@/pages/AssistantPage/managers/ConversationsRecordManager/ConversationRecordHelper"
import {
	IdRefWidgetWithPagination, IdRefWidgetWithPaginationItem,
	IdRefType, Debug, PendingNotificationUpdate, PendingNotificationCreateWithoutId,
	PendingNotificationCreate, UserMessage, Output, IdRefWidget,
	Message, PendingMessage, OutputsById
} from "deblank-api-types"
import { produce } from "immer"
import { useRecoilCallback } from "recoil"
import { v4 } from "uuid"
import { atomConversationsRecord } from "../Atom"
import { defaultOutputsIdCounts } from "../utils/defaultOutputsIdCounts"


const useAddPendingMessageToConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		userMessage: string,
		prompt?: string,
		idRefs?: IdRefWidgetWithPagination[] | IdRefWidgetWithPaginationItem[],
		generatedByWidget?: IdRefWidget,

	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			const key = currentConversation ? Object.keys(currentConversation.messages).length : 0
			const messageId = (`message-${key}`)

			const message: Message = {
				id: messageId,
				state: "loading",
				userMessage: {
					message: params.userMessage,
					idRefs: params.idRefs || null,

				},
				outputsIdsByIterations: [],
				relatedActions: [],
				generatedByWidget: params.generatedByWidget || null,
			}
			const pendingMessage: PendingMessage = {
				isAction: false,
				isEdit: false,
				messageId: messageId,
				userMessage: {
					message: params.userMessage,
					idRefs: params.idRefs || null,
				},
				prompt: params.prompt,
				currentPage: null,
			}

			if (currentConversation) {
				currentConversation.messages = {
					...currentConversation.messages,
					[messageId]: message,
				}
				currentConversation.pendingMessage = pendingMessage
			} else {
				// TODO: get from the backend?
				const id = v4()
				draft.conversations = {
					...draft.conversations,
					[id]: {
						id: id,
						creationDate: new Date().toISOString(),
						outputs: {},
						pinnedItems: [],
						messages: { [messageId]: message, },
						pendingNotifications: null,
						pendingMessage: pendingMessage,
						outputsIdCounts: defaultOutputsIdCounts,
						colorsPlaygroundIdRef: null,
						fontPlaygroundIdRef: null,
						imageModalIdRef: null,
					},
				}
			}
			draft.showNewChatView = false
		}))
	})
}

const useAddPendingActionToConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		userMessage: string,
		prompt: string,
		messageId: string,
		outputIndex: number,
		currentPage: number | null,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			const key = currentConversation ? Object.keys(currentConversation.messages).length : 0
			const messageId = (`message-${key}`)
			const originMessage = currentConversation.messages[params.messageId]
			const originOutputs = originMessage.outputsIdsByIterations[params.outputIndex]

			const outputId: IdRefWidgetWithPagination = {
				type: IdRefType.WidgetWithPagination,
				messageId: params.messageId,
				widgetId: originOutputs.outputId,
				widgetPageIndex: params.currentPage || 0,
			}

			const message: Message = {
				id: messageId,
				state: "loading",
				userMessage: {
					message: params.userMessage,
					idRefs: null,
				},
				outputsIdsByIterations: [],
				relatedActions: [],
				generatedByWidget: null,

			}

			const pendingMessage: PendingMessage = {
				isAction: true,
				isEdit: false,
				userMessage: {
					message: params.userMessage,
					idRefs: [outputId,],
				},
				messageId: messageId,
				prompt: params.prompt,
				currentPage: params.currentPage,
			}

			currentConversation.messages = {
				...currentConversation.messages,
				[messageId]: message,
			}
			currentConversation.pendingMessage = pendingMessage
		}))
	})
}

const useAddPendingRegenerateActionToConversation = () => {
	return useRecoilCallback(({ set, }) => (params: {
		userMessage: string,
		messageId: string,
		outputIndex: number,
		prompt: string,
		currentPage: number | null,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]
			const originMessage = currentConversation.messages[params.messageId]
			const originOutputs = originMessage.outputsIdsByIterations[params.outputIndex]

			const outputId: IdRefWidgetWithPagination = {
				type: IdRefType.WidgetWithPagination,
				messageId: params.messageId,
				widgetId: originOutputs.outputId,
				widgetPageIndex: params.currentPage || 0,
			}

			const pendingMessage: PendingMessage = {
				messageId: originMessage.id,
				userMessage: {
					message: params.userMessage,
					idRefs: [outputId,],
				},
				prompt: params.prompt,
				isAction: true,
				isEdit: true,
				currentPage: params.currentPage,
			}

			originMessage.state = "loading"
			currentConversation.pendingMessage = pendingMessage
		}))
	})
}



const useUpdateDebugHistory = () => {
	return useRecoilCallback(({ set, }) => (params: {
		debugHistory: Debug,
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			const currentConversation = draft.conversations[draft.activeConversationId!]

			if (params.debugHistory) {
				draft.debugConversations[currentConversation.id] = params.debugHistory
			}
		}))
	})
}

const useDispatchUpdateNotifications = () => {
	return useRecoilCallback(({ set, }) => (params: {
		notifications: Omit<PendingNotificationUpdate, "type">[],
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			if (draft.loadActiveConversation) {
				return
			}
			const activeConversation = draft.conversations[draft.activeConversationId!]

			if (!activeConversation) {
				console.error("Active conversation not found for adding update notifications")
				return
			}

			const pendingUpdates: PendingNotificationUpdate[] = []

			params.notifications.forEach((notification) => {
				notification.outputs.forEach((output) => {
					activeConversation.outputs![output.id] = output
				})
				pendingUpdates.push({
					...notification,
					type: "update",
				})
			})

			activeConversation.pendingNotifications
				? activeConversation.pendingNotifications.push(...pendingUpdates)
				: activeConversation.pendingNotifications = pendingUpdates

			draft.conversationStateSyncStatus = "pendingSave"
		}))
	})
}

const useDispatchCreateNotifications = () => {
	const { onTrackEvents, } = useTrackPendingNotifications()

	return useRecoilCallback(({ set, }) => (params: {
		notifications: (Omit<PendingNotificationCreateWithoutId, "type"> & { generatedByWidget?: IdRefWidget, })[],
	}) => {
		set(atomConversationsRecord, previous => produce(previous, draft => {
			if (draft.loadActiveConversation) {
				return
			}
			const activeConversation = draft.conversations[draft.activeConversationId!]
			const currentOutputsIds = activeConversation.outputsIdCounts

			if (!activeConversation) {
				console.error("Active conversation not found for adding create notifications")
				return
			}

			const pendingCreates: (PendingNotificationCreate & { userMessage: UserMessage | null, })[] = []

			params.notifications.forEach((notification) => {

				const messageLength = activeConversation ? Object.keys(activeConversation.messages).length : 0
				const messageId = `message-${messageLength}`

				// Increment the output count
				currentOutputsIds[notification.output.type]++

				const outputsWithId: OutputsById = {}

				// Generate similar ids to the ones generated by the backend
				const outputKey = `${notification.output.type}-${currentOutputsIds[notification.output.type]}`

				if (notification.output.data.isPaginated) {
					outputsWithId[outputKey] = {
						...notification.output,
						id: outputKey,
						data: {
							...notification.output.data,
							pages: notification.output.data.pages.map((page, index) => ({
								...page,
								// eslint-disable-next-line max-nested-callbacks
								results: page.results.map((result, resultIndex) => ({
									...result,
									id: `${outputKey}-${index}-${resultIndex + 1}`,
								})),
							})),
						},
					} as Output
				} else {
					outputsWithId[outputKey] = {
						...notification.output,
						id: outputKey,
						data: {
							...notification.output.data,
							results: [
								...notification.output.data.results.map((result, index) => ({
									...result,
									id: `${outputKey}-${index + 1}`,
								})),
							],
						},
					} as Output
				}

				activeConversation.outputs = {
					...activeConversation.outputs,
					...outputsWithId,
				}

				activeConversation.messages = {
					...activeConversation.messages,
					[messageId]: {
						id: messageId,
						state: "success",
						userMessage: notification.userMessage,
						outputsIdsByIterations: [{
							activePageIndex: notification.output.data.isPaginated ? 0 : null,
							outputId: outputsWithId[outputKey].id,
							message: null,
							numberOfViewsShowed: 0,
						},],
						relatedActions: notification.relatedActions,
						generatedByWidget: notification.generatedByWidget || null,
					},
				}

				pendingCreates.push({
					type: "create",
					internalMessageForAgent: notification.internalMessageForAgent,
					userMessage: notification.userMessage,
					messageId: messageId,
					outputs: Object.values(outputsWithId),
				})

			})

			onTrackEvents(pendingCreates)

			activeConversation.pendingNotifications
				? activeConversation.pendingNotifications.push(...pendingCreates)
				: activeConversation.pendingNotifications = pendingCreates
			draft.conversationStateSyncStatus = "pendingSave"
		}))
	})
}

export const settersAssistantResponseTools = {
	useAddPendingMessageToConversation,
	useAddPendingActionToConversation,
	useAddPendingRegenerateActionToConversation,
	useDispatchUpdateNotifications,
	useDispatchCreateNotifications,
	useUpdateDebugHistory,
}
