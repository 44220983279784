import React, { useEffect, useState } from "react"
import Styles from "./MessageList.module.scss"
import ChatBubbleUser from "@/components/Chat/BubbleUser/BubbleUser"
import { IdRefType } from "deblank-api-types"
import WidgetSelector from "./WidgetSelector/WidgetSelector"
import WidgetWrapperSelector from "./WidgetSelector/WidgetWrapper/WidgetWrapperSelector"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import ArrowDownIcon from "deblank-common/src/assets/images/icon-library/arrow-down.svg"
import { AnimatePresence, motion } from "framer-motion"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { useRecoilValue } from "recoil"
import { loadingMessageProps, scrollButtonProps } from "./MessageListAnimation"
import { useWindowSize } from "@/hooks/useWindowsSize"
import { LayoutSizes } from "../../AssistantLayout/LayoutSizes"
import { selectorsUITemporary } from "@/recoil/Ui/Temporary/Selectors"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import ConversationLoadManager from "@/pages/AssistantPage/managers/ConversationLoadManager/ConversationLoadManager"
import { getAccessors } from "@/utils/Accessor"
import { EnvHelperClient } from "@/utils/envHelper"
import AlphaUsersModal from "../AlphaUsersComponents/AlphaUsersModal/AlphaUsersModal"

const [
	getUserInteractAlphaModal,
	setUserInteractAlphaModal,
] = getAccessors<boolean | null>(
	"deblankUserInteractAlphaModal",
	() => localStorage,
	() => null
)

type Props = {
	htmlConversationContainerRef: React.MutableRefObject<HTMLDivElement | null>,
}

const MessageList = (props: Props) => {

	const [showScrollButton, setShowScrollButton,] = useState(false)
	const [showAlphaUsersModal, setShowAlphaUsersModal,] = useState(false)

	const messages = useRecoilValue(selectorsMessages.messagesInCurrentConversation)
	const areMessagesLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const isSidebarOpen = useRecoilValue(selectorsUITemporary.isSidebarOpen)
	const isPinnedListOpen = useRecoilValue(selectorsUITemporary.isPinnedListOpen)
	const isDebugPanelOpen = useRecoilValue(selectorsUITemporary.isDebugPanelOpen)
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)

	const windowSize = useWindowSize()

	useEffect(() => {
		handleScrollToBottom({ isAnimated: false, })
	}, [
		activeConversationId,
		loadConversationState,
	])

	useEffect(() => {
		handleScrollToBottom({ isAnimated: true, })
		const userInteractWithModal = getUserInteractAlphaModal()
		if (!userInteractWithModal
			&& messages
			&& (messages.length >= EnvHelperClient.alphaUserModalMessages)
		) {
			setShowAlphaUsersModal(true)
		}
	}, [
		areMessagesLoading,
		messages?.length,
	])

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.innerHeight + window.scrollY
			const isBottom = !(scrollPosition >= (document.documentElement.scrollHeight - 60))

			setShowScrollButton(isBottom)
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [
		messages,
		windowSize.isMobile,
	])

	const handleScrollToBottom = (params: { isAnimated?: boolean, }) => {
		if (props.htmlConversationContainerRef.current) {
			window.scrollTo({
				top: props.htmlConversationContainerRef.current.scrollHeight,
				behavior: params.isAnimated ? "smooth" : "instant",
			})
		}
	}

	const containerWidth = props.htmlConversationContainerRef.current!.getBoundingClientRect().width

	const getLeftPosition = () => {
		const whiteSpace = windowSize.width - containerWidth
			- (isSidebarOpen ? LayoutSizes.sidebar : 0)
			+ (isPinnedListOpen ? LayoutSizes.pinnedbar : 0)
			+ (isDebugPanelOpen ? LayoutSizes.pinnedbar : 0)

		return (windowSize.width - containerWidth) - (whiteSpace / 2)
	}

	if (!messages || messages.length === 0) {
		return null
	}

	return (
		<>
			{loadConversationState
				&& <ConversationLoadManager />
			}
			<AnimatePresence>
				{showAlphaUsersModal && <AlphaUsersModal
					onClose={() => setShowAlphaUsersModal(false)}
					onInteract={() => setUserInteractAlphaModal(true)}
				/>}
			</AnimatePresence>

			{
				messages.map((message, i) => (
					<section key={`${message.id}-${i}`}>
						{message.userMessage
							&& <div className={Styles.bubble_container}>
								<ChatBubbleUser
									message={message.userMessage.message}
									idRefs={message.userMessage.idRefs}

								/>
							</div>
						}

						{message.outputsIdsByIterations
							&& message.outputsIdsByIterations.map((iteration, iterationIndex) => (
								<WidgetWrapperSelector
									key={`${message.id}-${iterationIndex}`}
									conversationId={activeConversationId}
									idRef={{
										type: IdRefType.Widget,
										messageId: message.id,
										widgetId: iteration.outputId,
									}}
									activePageIndex={iteration.activePageIndex}
									iterationIndex={iterationIndex}
									message={iteration.message}
									onScrollToBottom={() => handleScrollToBottom({ isAnimated: true, })}
									containerWidth={
										props.htmlConversationContainerRef.current!.getBoundingClientRect().width
									}
									isLastOutputMessage={(i === messages!.length - 1)
										&& (iterationIndex === message.outputsIdsByIterations.length - 1)}
								>
									<WidgetSelector
										key={iteration.outputId}
										activePageIndex={iteration.activePageIndex || 0}
										idRef={{
											type: IdRefType.Widget,
											messageId: message.id,
											widgetId: iteration.outputId,
										}}
									/>
								</WidgetWrapperSelector>
							))
						}
					</section>
				))
			}
			<AnimatePresence>
				{(showScrollButton && !areMessagesLoading && !loadConversationState)
					&& <motion.div className={Styles.scroll_wrapper}
						style={{
							"--left_position": `${getLeftPosition()}px`,
							"--container_width": `${containerWidth}px`,
						} as React.CSSProperties}
						{...scrollButtonProps}
					>
						<ButtonIcon
							type="button"
							onClick={() => handleScrollToBottom({ isAnimated: true, })}
							customStyles={{ variant: "secondary", }}
							iconSVGComponent={ArrowDownIcon}
						/>
					</motion.div>
				}
			</AnimatePresence>

			<AnimatePresence>
				{(areMessagesLoading) && (
					<div className={Styles.loading_message}
						style={{
							"--left_position": `${getLeftPosition()}px`,
							"--container_width": `${containerWidth}px`,
						} as React.CSSProperties}>
						<motion.p
							className={Styles.loading_text}
							{...loadingMessageProps}
						>
							Loading results...
						</motion.p>
					</div>
				)}
			</AnimatePresence>
		</>
	)
}

export default MessageList
