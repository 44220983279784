import React, { useContext, useState } from "react"
import Styles from "./WidgetPinnedImages.module.scss"
import ExportIcon from "deblank-common/src/assets/images/icon-library/export.svg"
import Actions from "../../../Actions/Actions"
import usePresetActions from "../../../Actions/usePresetActions"
import classNames from "classnames"
import OpenIcon from "deblank-common/src/assets/images/icon-library/maximize.svg"
import { CommonWidgetImageItemProps } from "../WidgetImagesTypes"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { DisplayWidgetType, ImageResponse, SearchFrom, SearchResponse } from "deblank-api-types"
import LinkIcon from "deblank-common/src/assets/images/icon-library/link.svg"
import { ExportHelper } from "@/utils/exportHelper"
import Tooltip from "@/components/Tooltip/Tooltip"
import InfoIcon from "deblank-common/src/assets/images/icon-library/info.svg"
import { settersCommon } from "@/recoil/ConversationsRecord/Setters/Common"
import { widgetAction } from "@/pages/AssistantPage/components/CurrentConversation/MessageList/WidgetSelector/WidgetsTypes"
import ImageByWidgetType from "@/components/ImageByWidgetType/ImageByWidgetType"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import ImageManager from "@/utils/ImageManager"
import { useRecoilValue } from "recoil"


const WidgetPinnedImages = (props: CommonWidgetImageItemProps) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const [actionsOpen, setActionsOpen,] = useState<boolean>(false)

	const setImageModal = settersCommon.useSetImageModalIdRef()
	const activeConversationId = useRecoilValue(selectorsConversations.activeConversationId)
	const { pinPresetAction, sendToChatPresetAction, } = usePresetActions({
		idRef: props.idRef,
		widgetType: props.widgetType,
	})

	const searchDetails = props.widgetType === DisplayWidgetType.Searches && props.widgetItemDetails as SearchResponse

	const handleExportFromSearch = () => {
		const widget = props.widgetItemDetails as SearchResponse
		ExportHelper.fromImage(widget.imageUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const handleExportFromGeneratedImage = async () => {
		const widget = props.widgetItemDetails as ImageResponse

		const getSignedUrl = await ImageManager.getImageUrl({
			imageFileName: widget.imageFileName,
			conversationId: activeConversationId!,
		})

		ExportHelper.fromImage(getSignedUrl.signedUrl)
		trackConversationEvent({
			eventName: "Export",
			widgetType: DisplayWidgetType.Images,
			responseId: props.idRef.messageId,
			outputId: props.idRef.widgetId,
		})
	}

	const handleOpenModal = () => {
		trackConversationEvent({
			eventName: "OpenImageModal",
			widgetType: props.widgetType,
			responseId: props.idRef.messageId,
		})
		setImageModal({ idRef: props.idRef, })
	}

	const getActions = () => {

		const actionList: widgetAction[] = [
			pinPresetAction,
		]

		if (searchDetails) {
			const newActions = [
				{
					text: "Go to site",
					icon: LinkIcon,
					onClick: () => {
						trackConversationEvent({
							eventName: "ClickImageLink",
							widgetType: DisplayWidgetType.Images,
							responseId: props.idRef.messageId,
						})
						window.open(searchDetails.link, "_blank")
					},
				},
			]
			if (searchDetails.searchFrom === SearchFrom.Freeflo) {
				newActions.push(...[{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromSearch(),
				}, {
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
			} else {
				newActions.push({
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				})
			}

			actionList.push(...newActions)

		} else {
			actionList.push(...[
				sendToChatPresetAction,
				{
					text: "Export",
					icon: ExportIcon,
					onClick: () => handleExportFromGeneratedImage(),
				},
				{
					text: "Open",
					icon: OpenIcon,
					onClick: handleOpenModal,
				},])
		}
		return actionList
	}

	const wrapperClasses = classNames(
		Styles.wrapper,
		"actions_handler",
		{
			[Styles.is_open]: actionsOpen,
		}
	)

	const isDribbbleSearch = searchDetails && searchDetails.searchFrom === SearchFrom.Dribbble

	return (
		<figure className={wrapperClasses}>
			<button className={Styles.img}
				onClick={handleOpenModal}>
				<ImageByWidgetType widgetType={props.widgetType}
					widgetDetails={props.widgetItemDetails}
				/>
			</button>
			{isDribbbleSearch && <div className={Styles.dribbble_info}>
				<Tooltip
					tooltipContent={<p>Dribbble items cannot <br /> be downloaded</p>}
					customStyles={{
						position: "right",
					}}
				>
					<InfoIcon className={`${Styles.dribbble_info_icon} disabled_custom_color`} />
				</Tooltip>
			</div>}
			<Actions
				actionList={getActions()}
				onSetActionsOpen={setActionsOpen}
				customStyles={{
					actionType: "pinItem",
				}}
			/>
		</figure>
	)
}

export default WidgetPinnedImages
