import React, { useEffect, useRef } from "react"
import { trpc } from "@/providers/TRPCProvider"
import { settersConversations } from "@/recoil/ConversationsRecord/Setters/Conversations"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"
import { useRecoilValue } from "recoil"
import { Conversation, } from "deblank-api-types"
import { SaveStateMachine, SaveState } from "@/helpers/SaveStateMachine"
import { getLastMessagesInConversation } from "./ConversationSaveManagerHelper"
import * as Sentry from "@sentry/react"

type ConversationSaveData = {
	conversation: Conversation,
	conversationName: string,
}

const ConversationSaveManager = () => {

	const conversationSaveMachineRef = useRef<SaveStateMachine<ConversationSaveData> | null>(null)

	const conversationSaveState = useRecoilValue(selectorsConversations.conversationSaveState)
	const currentConversation = useRecoilValue(selectorsConversations.currentConversation)
	const historyConversations = useRecoilValue(selectorsConversations.historyConversations)

	const setConversationSaveState = settersConversations.useSetConversationSaveState()
	const updateConversationModifiedAt = settersConversations.useUpdateConversationModifiedAt()

	const updateConversationById = trpc.assistant.conversation.updateConversationById.useMutation()

	const saveConversationData = async (conversationDetails: ConversationSaveData) => {
		const lastMessages = getLastMessagesInConversation({ conversation: conversationDetails.conversation, })

		try {
			const saveConversation = await updateConversationById.mutateAsync({
				conversationId: conversationDetails.conversation.id,
				conversation: conversationDetails.conversation,
				metadata: {
					conversationName: conversationDetails.conversationName,
					messages: lastMessages.messages,
					outputs: lastMessages.outputs,
				},
			})

			updateConversationModifiedAt({
				conversationId: saveConversation.id,
				updatedAt: saveConversation.updatedAt,
			})
		}
		catch (error) {
			const errorMessage = "Error saving conversation"
			console.error(errorMessage, error)
			Sentry.captureException(error)
			throw error
		}
	}

	const onSaveStateChange = (state: SaveState) => {
		if (state === SaveState.Idle) {
			setConversationSaveState("idle")
		} else if (state === SaveState.Saving) {
			setConversationSaveState("saving")
		} else if (state === SaveState.Error) {
			setConversationSaveState("saveError")
		}
	}
	useEffect(() => {
		conversationSaveMachineRef.current = new SaveStateMachine<ConversationSaveData>({
			saveFunction: saveConversationData,
			notifyStateChange: onSaveStateChange,
		})
	}, [])

	useEffect(() => {
		if (conversationSaveState !== "idle") {
			const handleBeforeUnload = (event: BeforeUnloadEvent) => {
				event.preventDefault()
				event.returnValue = ""
			}

			window.addEventListener("beforeunload", handleBeforeUnload)

			return () => {
				window.removeEventListener("beforeunload", handleBeforeUnload)
			}
		}
	}, [conversationSaveState,])

	useEffect(() => {
		if (conversationSaveState === "pendingSave" && currentConversation) {
			const conversationName = historyConversations![currentConversation.id].metadata.conversationName

			conversationSaveMachineRef.current!.onDataModified({
				conversation: currentConversation,
				conversationName: conversationName,
			})
		}
	}, [
		conversationSaveState,
	])

	return (
		<></>
	)
}

export default ConversationSaveManager
