import { DisplayWidgetType, IdRefType, IdRefWidgetWithPaginationItem } from "deblank-api-types"

const selectedOutputIdColors: IdRefWidgetWithPaginationItem = {
	type: IdRefType.WidgetWithPaginationItem,
	messageId: "draft-message-id",
	widgetId: "draft-color-id",
	widgetItemId: "draft-color-item-id",
	widgetPageIndex: 0,
	widgetItemIndex: 0,
}

const selectedOutputIdFonts: IdRefWidgetWithPaginationItem = {
	type: IdRefType.WidgetWithPaginationItem,
	messageId: "draft-message-id",
	widgetId: "draft-font-id",
	widgetItemId: "draft-font-item-id",
	widgetPageIndex: 0,
	widgetItemIndex: 0,
}

const selectedOutputIdImages: IdRefWidgetWithPaginationItem = {
	type: IdRefType.WidgetWithPaginationItem,
	messageId: "draft-message-id",
	widgetId: "draft-images-id",
	widgetItemId: "draft-images-item-id",
	widgetPageIndex: 0,
	widgetItemIndex: 0,
}


export const RelatedActionsDefaultValues = {
	colors: {
		idRef: selectedOutputIdColors,
		widgetItemDetails: {
			id: "",
			palette: [],
		},
	},
	fonts: {
		idRef: selectedOutputIdFonts,
		widgetItemDetails: {
			id: "",
			slug: "",
		},
		metadata: {
			baseFont: null,
			title: "",
			description: "",
			bodyText: "",
			pairing: null,
		},
	},
	images: {
		idRef: selectedOutputIdImages,
		widgetItemDetails: {
			id: "",
			imageFileName: "",
			promptRefinedByAgent: null,
			imageAnalysis: null,
		},
		widgetType: DisplayWidgetType.Images,
	},
}
