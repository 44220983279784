/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, { useContext, useEffect, useState } from "react"
import Styles from "./WidgetWrapper.module.scss"
import ButtonIcon from "@/components/Buttons/ButtonIcon/ButtonIcon"
import Button from "@/components/Buttons/Button/Button"
import StarIcon from "deblank-common/src/assets/images/icon-library/star.svg"
import InfoIcon from "deblank-common/src/assets/images/icon-library/info.svg"
import RegenerateIcon from "deblank-common/src/assets/images/icon-library/regenerate.svg"
import AnswerSelector from "../AnswerSelector/AnswerSelector"
import { DisplayWidgetType } from "deblank-api-types"
import { useRecoilValue } from "recoil"
import { CommonWidgetWrapperProps } from "../WidgetWrapperTypes"
import classNames from "classnames"
import SearchBadget from "../SearchBadget/SearchBadget"
import { TrackConversationEventContext } from "@/pages/AssistantPage/TrackConversationEventProvider"
import { createRegenerateNotificationMessage } from "@/utils/RegenerateUtils"
import FeedbackAction from "../../FeedbackAction/FeedbackAction"
import {
	isExplanationActionRestricted, isRegenerateActionRestricted, getFeedbackOptions
} from "./WidgetWrapperHelper"
import { selectorsMessages } from "@/recoil/ConversationsRecord/Selectors/Messages"
import { selectorsWidgets } from "@/recoil/ConversationsRecord/Selectors/Widgets"
import { settersMessages } from "@/recoil/ConversationsRecord/Setters/Messages"
import { settersAssistantResponseTools } from "@/recoil/ConversationsRecord/Setters/AssistantResponseTools"
import * as Sentry from "@sentry/react"
import RelatedActionsManager from "../../../../RelatedActions/RelatedActionsManager/RelatedActionsManager"
import { useNavigate } from "@tanstack/react-router"
import RelatedIcon from "deblank-common/src/assets/images/icon-library/related.svg"
import ArrowTopRight from "deblank-common/src/assets/images/icon-library/arrow-top-right.svg"
import { AnimatePresence, } from "framer-motion"
import Tooltip from "@/components/Tooltip/Tooltip"
import { selectorsConversations } from "@/recoil/ConversationsRecord/Selectors/Conversations"


const MAX_REGENERATION_LIMIT = 2

const WidgetWrapper = (props: CommonWidgetWrapperProps) => {
	const { trackConversationEvent, } = useContext(TrackConversationEventContext)
	const navigate = useNavigate()

	const [currentPageIndex, setCurrentPageIndex,] = useState<number>(0)
	const [showRelatedActions, setShowRelatedActions,] = useState(props.isLastOutputMessage)

	const message = useRecoilValue(selectorsMessages.messageById(props.idRef.messageId))
	const output = useRecoilValue(selectorsWidgets.outputById(props.idRef.widgetId))
	const someMessageIsLoading = useRecoilValue(selectorsMessages.someMessageIsLoading)
	const loadConversationState = useRecoilValue(selectorsConversations.loadConversationState)
	const isLastMessage = useRecoilValue(selectorsMessages.isThisMessageLast(props.idRef.messageId))
	const isSavingNewConversationName = useRecoilValue(selectorsConversations.isSavingNewConversationName)
	const numberOfViewsShowed = useRecoilValue(selectorsMessages.getNumberOfViewsShowed({
		idRef: props.idRef,
		outputsIdsByIterationIndex: props.iterationIndex,
	}))

	const updateIndexState = settersMessages.useUpdateIterationResponseIndexInMessage()
	const addPendingActionMessage = settersAssistantResponseTools.useAddPendingActionToConversation()
	const addCreateNotification = settersAssistantResponseTools.useDispatchCreateNotifications()
	const addRegenerateMessage = settersAssistantResponseTools.useAddPendingRegenerateActionToConversation()

	const isLoading = someMessageIsLoading || loadConversationState || isSavingNewConversationName
	const feedbackOptions = getFeedbackOptions(output.type)
	const hideExplanationButton = isExplanationActionRestricted(output.type)
	const hideRegenerateButton = isRegenerateActionRestricted(output.type)

	useEffect(() => {
		setShowRelatedActions(props.isLastOutputMessage)
	}, [props.isLastOutputMessage,])

	useEffect(() => {
		setCurrentPageIndex(props.activePageIndex || 0)
	}, [props.activePageIndex,])

	const handleUpdateIndex = (newIndex: number) => {
		setCurrentPageIndex(newIndex)
		updateIndexState({
			index: newIndex,
			outputsIdsByIterationIndex: props.iterationIndex,
			idRef: props.idRef,
		})
	}

	const handleNavigateToOriginalAnswer = () => {
		navigate({
			hash: message.generatedByWidget!.widgetId,
			to: "/",
			mask: { to: "/", },
		})
	}

	const handleNextAnswer = () => {
		trackConversationEvent({
			eventName: "ClickOnNextAnswer",
			widgetType: output.type,
			responseId: message.id,
		})
		handleUpdateIndex(currentPageIndex + 1)
	}

	const handlePrevAnswer = () => {
		if (currentPageIndex > 0) {
			trackConversationEvent({
				eventName: "ClickOnPrevAnswer",
				widgetType: output.type,
				responseId: message.id,
			})
			handleUpdateIndex(currentPageIndex - 1)
		}
	}

	const handleManualRegenerate = () => {
		if (numberOfViewsShowed < MAX_REGENERATION_LIMIT) {
			handleUpdateIndex(currentPageIndex + 1)
		} else {
			// Notify about reaching max regeneration limit
			const maxRegenerationLimitNotification = createRegenerateNotificationMessage(output.type)
			addCreateNotification({
				notifications: [maxRegenerationLimitNotification,],
			})
			props.onScrollToBottom()
		}
	}

	const handleAssistantRegenerate = (params: { userMessage: string, prompt: string, }) => {
		if (numberOfViewsShowed < MAX_REGENERATION_LIMIT) {
			addRegenerateMessage({
				messageId: message.id,
				outputIndex: props.iterationIndex,
				userMessage: params.userMessage,
				prompt: `${params.prompt}.
**Important:** this is a regenerate action.`,
				currentPage: currentPageIndex,
			})
		} else {
			const maxRegenerationLimitNotification = createRegenerateNotificationMessage(output.type)
			addCreateNotification({
				notifications: [maxRegenerationLimitNotification,],
			})
			props.onScrollToBottom()
		}
	}

	const handleRegenerate = () => {
		trackConversationEvent({
			eventName: "Regenerate",
			widgetType: output.type,
			responseId: message.id,
		})
		const widgetType = output.type

		switch (widgetType) {
			case DisplayWidgetType.Fonts:
			case DisplayWidgetType.MockupColors:
			case DisplayWidgetType.MockupFonts:
				handleManualRegenerate()
				break
			case DisplayWidgetType.Colors:
				handleAssistantRegenerate({
					userMessage: "Colors Regenerate",
					prompt: `Regenerate ${output.data.pages[currentPageIndex].results.length} new
color palettes, clearly distinct from the previous results`,
				})
				break
			case DisplayWidgetType.Images:
				handleAssistantRegenerate({
					userMessage: "Images Regenerate",
					prompt: `Get new images. Consider the business description, my previous image
request and introduce subtle changes so that the results are different from the previous ones.`,
				})
				break
			case DisplayWidgetType.Searches:
			case DisplayWidgetType.AccessibilityColors:
			case DisplayWidgetType.ColorsExplanations:
			case DisplayWidgetType.FontsExplanations:
			case DisplayWidgetType.Text:
				console.error(`${widgetType} is not implemented yet in regenerate`)
				break
			default: {
				const _exhaustiveCheck: never = widgetType
				const errorMessage = `Error regenerating with this widget: ${_exhaustiveCheck}`
				Sentry.captureMessage(errorMessage)
				console.error(errorMessage)
			}

		}
	}


	const handleJustification = () => {
		trackConversationEvent({
			eventName: "GetExplanation",
			widgetType: output.type,
			responseId: message.id,
		})
		addPendingActionMessage({
			messageId: message.id,
			outputIndex: props.iterationIndex,
			userMessage: "Get answer information",
			prompt: "Generate an explanation for the following results",
			currentPage: props.activePageIndex,
		})
	}

	const answerClasses = classNames(
		Styles.answer,
		{
			[Styles.answer_container_fonts_widgets]: output.type === DisplayWidgetType.Fonts,
			[Styles.answer_container_images_widgets]:
				output.type === DisplayWidgetType.Images
				|| output.type === DisplayWidgetType.Searches,
			[Styles.answer_container_explanation_widgets]: output.type === DisplayWidgetType.ColorsExplanations,
			[Styles.answer_container_explanation_widgets]: output.type === DisplayWidgetType.FontsExplanations,
		}
	)

	const leftColumnClasses = classNames(
		Styles.left_column, {
		[Styles.left_column_expanded]: showRelatedActions,
	})

	const widgetBottomRow = classNames(
		Styles.widget_bottom_row,
		{
			[Styles.widget_bottom_row_previous]: !isLastMessage,
		}
	)

	return (
		<div className={Styles.container} id={props.idRef.widgetId}>
			<div className={Styles.widget_top_row}>
				<div className={Styles.title_container}>
					<StarIcon />
					<p>Answer</p>
				</div>

				{!!numberOfViewsShowed && <AnswerSelector
					answersAmount={numberOfViewsShowed}
					selectedAnswerIndex={currentPageIndex}
					onNextAnswer={handleNextAnswer}
					onPrevAnswer={handlePrevAnswer}
				/>}
			</div>

			<div className={Styles.widget_answer_container}>
				<div className={leftColumnClasses} />

				<div className={Styles.right_column}>
					{props.message && (
						<p className={Styles.message_to_user}>
							{props.message}
						</p>
					)}
					<div className={answerClasses}>
						{props.children}
					</div>
					{output.type === DisplayWidgetType.Searches && <SearchBadget idRef={props.idRef} />}
					<div className={widgetBottomRow}>
						<div className={Styles.actions_container}>
							<FeedbackAction
								messageId={message.id}
								type={output.type}
								likeFeedbackForm={feedbackOptions.likeFeedbackForm}
								dontLikeFeedbackForm={feedbackOptions.dontLikeFeedbackForm}
							/>
							{!hideExplanationButton && isLastMessage && (
								<Tooltip
									tooltipContent={"Answer details"}
									customStyles={{ position: "top", animationDelay: "short", }}
								>
									<ButtonIcon
										type="button"
										onClick={handleJustification}
										disabled={isLoading}
										customStyles={{ variant: "ghost", }}
										iconSVGComponent={InfoIcon}
									/>
								</Tooltip>
							)}
							{!props.isLastOutputMessage && <Tooltip
								tooltipContent={"Related actions"}
								customStyles={{ position: "top", animationDelay: "short", }}
							>
								<ButtonIcon
									type="button"
									onClick={() => setShowRelatedActions(prev => !prev)}
									disabled={isLoading}
									customStyles={{ variant: "ghost", }}
									iconSVGComponent={RelatedIcon}
								/>
							</Tooltip>
							}
						</div>
						<div className={Styles.actions_container}>
							{message?.generatedByWidget && <Button
								type="button"
								disabled={isLoading}
								onClick={handleNavigateToOriginalAnswer}
								icon={{ SVGComponent: ArrowTopRight, position: "left", }}
								customStyles={{ variant: "secondary", }}
							>
								Original Answer
							</Button>}
							{!hideRegenerateButton && isLastMessage && (
								<Button
									type="button"
									disabled={isLoading}
									onClick={handleRegenerate}
									icon={{ SVGComponent: RegenerateIcon, position: "left", }}
									customStyles={{ variant: "tertiary", }}
								>
									Regenerate
								</Button>
							)}
						</div>
					</div>
				</div>
			</div>
			<AnimatePresence>
				{(showRelatedActions)
					&& <RelatedActionsManager idRef={props.idRef}
						conversationId={props.conversationId!}
						containerWidth={props.containerWidth}
					/>
				}
			</AnimatePresence>
		</div>
	)
}


export default WidgetWrapper
